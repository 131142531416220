<template>
  <div class="home">
    <img alt="Vue logo" src="@/assets/logo.png" @click="test" />
    <HelloWorld msg="Welcome to Your Vue.js App" />
    <a-range-picker valueFormat="yyyy-MM-DD HH:mm:ss" v-model="testTime" />{{ testTime }}
    <upload-widget v-model="fileUrl"></upload-widget>
    {{ fileUrl }}
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      fileUrl: '',
      testTime: [],
    };
  },
  components: {},
  created() {},
  methods: {
    test() {
      this.clickMe();
    },
    async clickMe() {
let reqData =  {"loginTimes":0,"loginDate":"","id":0,"pwd":"","account":"","token":""};
let loginUrl = `/admin/login`;
let result = await this.axios.post(loginUrl,reqData);
console.log(result);
    },
  },
};
</script>
